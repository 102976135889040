// src/components/TimeConstraintsInput.js
import React from 'react';
import { Form } from 'react-bootstrap';

const TimeConstraintsInput = ({ timeConstraints, setTimeConstraints }) => {
  return (
    <Form.Group controlId="timeConstraints">
      <Form.Label>Time Constraints</Form.Label>
      <Form.Control
        type="text"
        value={timeConstraints}
        onChange={(e) => setTimeConstraints(e.target.value)}
        placeholder="Enter your time constraints, e.g. 30 minutes per day"
      />
    </Form.Group>
  );
};

export default TimeConstraintsInput;
