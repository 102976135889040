// src/components/WorkoutFrequencyInput.js
import React from 'react';
import { Form } from 'react-bootstrap';

const WorkoutFrequencyInput = ({ workoutFrequency, setWorkoutFrequency }) => {
  return (
    <Form.Group controlId="workoutFrequency">
      <Form.Label>Workout Frequency</Form.Label>
      <Form.Control
        type="text"
        value={workoutFrequency}
        onChange={(e) => setWorkoutFrequency(e.target.value)}
        placeholder="Enter your workout frequency, e.g. 5 times a week"
      />
    </Form.Group>
  );
};

export default WorkoutFrequencyInput;
