// src/components/ExperienceLevelInput.js
import React from 'react';
import { Form } from 'react-bootstrap';

const ExperienceLevelInput = ({ experienceLevel, setExperienceLevel }) => {
  return (
    <Form.Group controlId="experienceLevel">
      <Form.Label>Experience Level</Form.Label>
      <Form.Control
        type="text"
        value={experienceLevel}
        onChange={(e) => setExperienceLevel(e.target.value)}
        placeholder="Enter your experience level, e.g. beginner, intermediate, advanced"
        required
      />
    </Form.Group>
  );
};

export default ExperienceLevelInput;
