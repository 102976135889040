import React from 'react';
import { Form } from 'react-bootstrap';

const WeightInput = ({ weight, setWeight,  weightUnit, setWeightUnit }) => {
  return (
    <Form.Group controlId="weight">
      <Form.Label>Weight: {weight} {weightUnit}</Form.Label>
      <div className="d-flex">
        <Form.Control
            type="range"
            min="30"
            max="200"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}

        />
        <Form.Control as="select" value={weightUnit} onChange={(e) => setWeightUnit(e.target.value)}>
            <option value="kg">kg</option>
            <option value="lbs">lbs</option>
        </Form.Control>
    </div>
    </Form.Group>
  );
};

export default WeightInput;
