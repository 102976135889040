import React, { useState, useRef } from 'react';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';
import './App.css';
import GenderInput from './components/GenderInput';
import WeightInput from './components/WeightInput';
import HeightInput from './components/HeightInput';
import ActivityLevelInput from './components/ActivityLevelInput';
import FitnessGoalsInput from './components/FitnessGoalsInput';
import WorkoutFrequencyInput from './components/WorkoutFrequencyInput';
import HealthConditionsInput from './components/HealthConditionsInput';
import TimeConstraintsInput from './components/TimeConstraintsInput';
import ExperienceLevelInput from './components/ExperienceLevelInput';
import CustomConstraintsInput from './components/CustomConstraintsInput';
import EmailInput from './components/EmailInput';


function App() {
  const [gender, setGender] = useState('');
  const [weight, setWeight] = useState(60);
  const [height, setHeight] = useState('');
  const [email, setEmail] = useState('');
  const [activityLevel, setActivityLevel] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [heightUnit, setHeightUnit] = useState("cm");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [fitnessGoals, setFitnessGoals] = useState('');
  const [workoutFrequency, setWorkoutFrequency] = useState('');
  const [healthConditions, setHealthConditions] = useState('');
  const [timeConstraints, setTimeConstraints] = useState('');
  const [experienceLevel, setExperienceLevel] = useState('');
  const [customConstraints, setCustomConstraints] = useState('');
  const formRef = useRef(null);




  // Convert height to cm
const heightInCm = heightUnit === "in" ? parseFloat(height) * 2.54 : parseFloat(height);

// Convert weight to kg
const weightInKg = weightUnit === "lbs" ? parseFloat(weight) / 2.20462 : parseFloat(weight);




  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post('https://yas33n.pythonanywhere.com/api/generate-workout', {
      // age: age,
      gender: gender,
      weight: weightInKg,
      height: heightInCm,
      activity_level: activityLevel,
      fitness_goals: fitnessGoals,
      workout_frequency: workoutFrequency,
      health_conditions:healthConditions,
      time_constraints:timeConstraints,
      experience_level:experienceLevel,
      custom_constraints:customConstraints,
      email: email,
    });


      console.log(res);
      console.log(res.data.response);

      setLoading(false);
      setResponse(res.data.response);
    } catch (err) {
      setLoading(false);
      setResponse('An error occurred while processing your request. Please try again later.');
    }
  };

  return (
    <div className="App">
      <Container>
        <h1 className="text-purple">Workout Plan Generator</h1>
        <Form ref={formRef} onSubmit={handleSubmit} className="mb-4">
          <GenderInput gender={gender} setGender={setGender} />
          <WeightInput weight={weight} setWeight={setWeight} setWeightUnit={setWeightUnit} />
          <HeightInput height={height} setHeight={setHeight} setHeightUnit={setHeightUnit}/>
          <ActivityLevelInput activityLevel={activityLevel} setActivityLevel={setActivityLevel} />
          <FitnessGoalsInput fitnessGoals={fitnessGoals} setFitnessGoals={setFitnessGoals} />
          <WorkoutFrequencyInput workoutFrequency={workoutFrequency} setWorkoutFrequency={setWorkoutFrequency} />
          <HealthConditionsInput healthConditions={healthConditions} setHealthConditions={setHealthConditions} />
          <TimeConstraintsInput timeConstraints={timeConstraints} setTimeConstraints={setTimeConstraints} />
          <ExperienceLevelInput experienceLevel={experienceLevel} setExperienceLevel={setExperienceLevel} />
          <CustomConstraintsInput customConstraints={customConstraints} setCustomConstraints={setCustomConstraints} />
          <EmailInput email={email} setEmail={setEmail} />

          
          {/* Add more input fields for other user details */}
          <Button type="submit" variant="purple" disabled={loading || (formRef.current && !formRef.current.checkValidity())}>
            Generate Workout Plan
          </Button>

        </Form>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" variant="purple">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : null}
        {response ? (
          <Alert variant="info" className="mt-4">
            <div className="responseContainer">{response}</div>
          </Alert>
        ) : null}
      </Container>
    </div>
  );
}

export default App;
