import React from 'react';
import { Form } from 'react-bootstrap';

const GenderInput = ({ gender, setGender }) => {
  return (
    <Form.Group controlId="gender">
      <Form.Label>Gender</Form.Label>
      <Form.Select
        value={gender}
        onChange={(e) => setGender(e.target.value)}
      >
        <option value="">Select your gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="nonbinary">Non-binary</option>
        <option value="other">Other</option>
      </Form.Select>
    </Form.Group>
  );
};

export default GenderInput;
