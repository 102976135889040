// src/components/FitnessGoalsInput.js
import React from 'react';
import { Form } from 'react-bootstrap';

const FitnessGoalsInput = ({ fitnessGoals, setFitnessGoals }) => {
  return (
    <Form.Group controlId="fitnessGoals">
      <Form.Label>Fitness Goals</Form.Label>
      <Form.Control
        type="text"
        value={fitnessGoals}
        onChange={(e) => setFitnessGoals(e.target.value)}
        placeholder="Enter your fitness goals, e.g. gain muscle"
      />
    </Form.Group>
  );
};

export default FitnessGoalsInput;
