import React from 'react';
import { Form } from 'react-bootstrap';

const ActivityLevelInput = ({ activityLevel, setActivityLevel }) => {
  return (
    <Form.Group controlId="activityLevel">
      <Form.Label>Activity Level</Form.Label>
      <Form.Control
        as="select"
        value={activityLevel}
        onChange={(e) => setActivityLevel(e.target.value)}

      >
        <option value="">Select your activity level</option>
        <option value="sedentary">Sedentary</option>
        <option value="light">Light</option>
        <option value="moderate">Moderate</option>
        <option value="active">Active</option>
        <option value="very_active">Very Active</option>
      </Form.Control>
    </Form.Group>
  );
};

export default ActivityLevelInput;
