// src/components/HealthConditionsInput.js
import React from 'react';
import { Form } from 'react-bootstrap';

const HealthConditionsInput = ({ healthConditions, setHealthConditions }) => {
  return (
    <Form.Group controlId="healthConditions">
      <Form.Label>Health Conditions</Form.Label>
      <Form.Control
        type="text"
        value={healthConditions}
        onChange={(e) => setHealthConditions(e.target.value)}
        placeholder="Enter any health conditions, e.g. hypertension, diabetes"
      />
    </Form.Group>
  );
};

export default HealthConditionsInput;
