// src/components/CustomConstraintsInput.js
import React from 'react';
import { Form } from 'react-bootstrap';

const CustomConstraintsInput = ({ customConstraints, setCustomConstraints }) => {
  return (
    <Form.Group controlId="customConstraints">
      <Form.Label>Custom Constraints</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        value={customConstraints}
        onChange={(e) => setCustomConstraints(e.target.value)}
        placeholder="Specify any custom constraints, e.g. keep my Mondays free"
      />
    </Form.Group>
  );
};

export default CustomConstraintsInput;
