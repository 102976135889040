import React from 'react';
import { Form } from 'react-bootstrap';

const HeightInput = ({ height, setHeight, heightUnit, setHeightUnit }) => {
  return (
    <Form.Group controlId="height">
      <Form.Label>Height</Form.Label>
      <div className="d-flex">
        <Form.Control
          type="number"
          min="100"
          max="250"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          placeholder="Enter your height"
          className="mr-2"
        />
        <Form.Control as="select" value={heightUnit} onChange={(e) => setHeightUnit(e.target.value)}>
          <option value="cm">cm</option>
          <option value="in">in</option>
        </Form.Control>
      </div>
    </Form.Group>
  );
};

export default HeightInput;
